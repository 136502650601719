.choice {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainParth {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 5px;
  width: 800px;
}
.h {
  margin: 10px 0;
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.location {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
  margin: 0 10px;
}
.opt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  margin: 10px 0;
}
.opt select {
  border: none;
  color: #a09da1;
}
.p {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.labs {
  color: #a09da1;
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
}
.labsArea {
  margin-bottom: 15px;
}
.labsArea > * {
  margin: 0 10px;
}
.beet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background: #f4f4f4;
  border-radius: 6px;
}
.ccc {
  color: #c4c4c4;
  font-size: 16px;
}
.txt {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
}
.searchPart {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  margin: 10px 0;
}
.searchCombine {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 5px 5px 50px;
  margin: 0;
}
.underBTN {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
