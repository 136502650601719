.selectedArea {
  display: flex;
  justify-content: center;
  align-items: center;
}
.comp {
  color: #a09da1;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}
.info {
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 6px;
  width: 500px;
  padding: 10px;
}
.info span {
  color: #a09da1;
}
.select {
  color: #c4c4ca;
  width: 100%;
  height: 40px;
}
.sel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
