.head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.head h1 {
  color: #005792;
  margin-left: 15px;
}
.bus .p {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.sub {
  margin-top: 30px;
  font-weight: 500;
  font-size: 22px;
  color: #a09da1;
}
.tb {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 25px;
}
.topTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  background: linear-gradient(92.21deg, #005792 6.65%, #04118a 106.65%);
  box-shadow: 0px 6px 7px rgba(31, 31, 31, 0.15);
  border-radius: 6px;
  width: 280px;
  height: 45px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.inps {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.arrows > * {
  margin-left: 10px;
}
.table {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 3fr 2fr;
  margin-top: 20px;
}
table > * {
  justify-content: flex-start;
}
.tit {
  margin: 20px;
  background: #f4f4f4;
  border-radius: 6px;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 10px;
}
.borderDv {
  border: 1px solid #dde1eb;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
