.blueTitles {
  color: #1daaff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.blueTitles > * {
  margin-right: 10px;
}
.subInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.subInfo > * {
  margin-right: 10px;
}
.subInfo h5 {
  font-weight: 500;
  font-size: 18px;
  color: #a09da1;
}
.subInfo span {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
}
.choise {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px 20px 10px 20px;
}
.choise > * {
  margin-left: 10px;
}
.age {
  width: 250px;
  height: 110px;
  background: linear-gradient(
    88.56deg,
    rgba(0, 87, 146, 0.35) -23.12%,
    rgba(0, 87, 146, 0.4) -23.12%,
    #2fabff -23.11%,
    #04118a 104.28%
  );
  border-radius: 6px;
  padding: 10px;
}
.age h3 {
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin: 5px;
}
.topPart {
  background: #e3e3e3;
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 650px;
  padding: 5px 0;
  margin-bottom: 10px;
}
.topPart span {
  color: #475761;
}
.bottomLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
