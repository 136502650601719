.paymant {
  margin-top: 100px;
  padding: 50px 100px;
}
.paymant h3 {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 40px;
  text-align: center;
  color: #04118a;
}
.paymant h2 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  color: #04118a;
}
.paymant .p {
  font-family: "Poppins";
  font-size: 24px;
  text-align: center;
  color: #1daaff;
}
.cardsArea {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardsArea > * {
  margin: 0 20px;
}
.footBar {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #04118a;
}
