.topFoot {
  position: relative;
  margin-top: 100px;
}
.timer {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
}
.main {
  background: #ffffff;
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
  border-radius: 26px;
  height: 270px;
  width: 1000px;
  margin-top: 33px;
}
.main h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
  color: #005792;
  text-align: center;
}
.main p {
  font-family: "Poppins";
  font-size: 16px;
  color: #005792;
  text-align: center;
}
.topMain {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnArea {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
footer {
  background: #e4eefa;
  color: #005792;
  padding: 200px 100px 0 100px;
}
.menuArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.menuPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul {
  list-style: none;
}
ul span {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}
li {
  line-height: 27px;
}
.first {
  margin-right: 200px;
}
.endFooter {
  text-align: center;
  margin-bottom: 20px;
}
