.my {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;
}
.p {
  color: #a09da1;
  font-size: 14;
  margin-left: 20px;
  text-align: center;
}
