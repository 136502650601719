.mainText {
  padding: 100px;
}
.mainText h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 72px;
  text-align: center;
  color: #005792;
}
.mainText p {
  font-family: "Poppins";
  line-height: 27px;
  font-size: 18px;
  text-align: center;
  color: #2fabff;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.featNav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
  margin-bottom: 50px;
}
.mainAreaPart {
  margin-left: 50px;
}
.nav > * {
  margin-bottom: 25px;
}
