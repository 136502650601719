.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topBtn {
  display: flex;
  align-items: center;
}
.topBtn > * {
  margin-right: 10px;
}
.searchPart {
  position: relative;
  border-radius: 6px;
}
.searchPart > input {
  border: none;
  border-radius: 6px;
  background-color: #f4f4f4;
  width: 350px;
  height: 45px;
  padding-left: 15px;
}
.table {
  margin-top: 50px;
}
.headTable {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1400px;
  margin-bottom: 5px;
}
.myTable > * {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bodyTable {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  height: 60px;
  width: 1400px;
}
.bodyTable > * {
  margin-right: 5px;
}
.name {
  font-weight: 500;
  font-size: 12px;
  color: #a09da1;
  text-align: center;
}
.p {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a09da1;
}
