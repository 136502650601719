.log {
  background: linear-gradient(
    107.49deg,
    rgba(77, 124, 193, 0.23) 0%,
    rgba(165, 216, 255, 0.06) 100%
  );
  height: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.all {
  position: relative;
}
.imgArea {
  position: absolute;
  left: 37%;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(200, 200, 200, 0.22);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.login {
  background: #ffffff;
  box-shadow: 0px 4px 47px rgba(218, 218, 218, 0.18);
  border-radius: 16px;
  width: 50em;
  padding: 20px 40px;
  margin-top: 50px;
  padding-top: 50px;
}

.login h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #04118a;
  margin: 0 !important;
}
.login p {
  font-family: "Poppins";
  font-size: 18px;
  text-align: center;
  color: #04118a;
  margin: 10px !important;
  font-weight: normal;
  font-weight: 200;
}
.login .p {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4d7cc1;
  margin-top: 20px !important;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.to {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #04118a;
}
.to a {
  font-weight: 600 !important;
  font-size: 18px;
  color: #04118a;
}
