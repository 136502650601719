@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins";
}
a {
  text-decoration: none;
}

.Long_long__uJ_MY {
  display: grid;
  grid-template-columns: 30% 70%;
  justify-items: center;
  padding: 20px;
  width: 400px;
  height: 150px;
  border-radius: 26px;
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
}
.Long_imgArea__1fi3l {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Long_texts__3rgBW h2 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  margin: 5px 0 !important;
  color: #04118a;
}
.Long_texts__3rgBW h6 {
  font-family: "Poppins";
  font-size: 16px;
  margin: 0 !important;
}

.Header_head__1gLhw {
  height: 125px;
  background: #ffffff;
  box-shadow: 0px 4px 43px rgba(135, 135, 135, 0.1);
  display: grid;
  grid-template-columns: 5fr 2fr 5fr;
  justify-items: center;
  place-items: center;
}
.Header_head__1gLhw a {
  text-decoration: none;
  font-family: "Poppins";
  font-size: 16px;
  color: #005792;
}
.Header_leftHead__2lU8N {
  display: grid;
  grid-template-columns: 6fr 3fr 3fr;
  justify-items: flex-end;
  place-items: center;
}
.Header_navigation__AjqId {
  padding-left: 50%;
}
.Header_rightHead__3R2sf {
  display: grid;
  grid-template-columns: 3fr 3fr 6fr;
  justify-items: start;
  place-items: center;
}
.Header_lagnSelect__2AOnY {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header_lagnSelect__2AOnY select {
  border: none;
}

.MyButton_myBtn__3cBLO {
  background: linear-gradient(92.21deg, #005792 6.65%, #04118a 106.65%);
  box-shadow: 0px 6px 7px rgba(31, 31, 31, 0.15);
  border-radius: 20px;
  width: 200px;
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.MyButton_myBtn__3cBLO span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
}

.Features_mainText__2cIfg {
  padding: 100px;
}
.Features_mainText__2cIfg h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 72px;
  text-align: center;
  color: #005792;
}
.Features_mainText__2cIfg p {
  font-family: "Poppins";
  line-height: 27px;
  font-size: 18px;
  text-align: center;
  color: #2fabff;
}
.Features_btn__3OdFF {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.Features_featNav__I7l_R {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
  margin-bottom: 50px;
}
.Features_mainAreaPart__2e97n {
  margin-left: 50px;
}
.Features_nav__2XSVY > * {
  margin-bottom: 25px;
}

.MainArea_all__OdbGV {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MainArea_mainArea__37P8g {
  padding: 50px 100px;
  background: #ffffff;
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
  border-radius: 26px;
}
.MainArea_mainArea__37P8g h3 {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 40px;
  color: #005792;
}
.MainArea_mainArea__37P8g h2 {
  color: #005792;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 48px;
}
.MainArea_mainArea__37P8g p {
  font-size: 18px;
  color: #005792;
}
.MainArea_main__1_I88 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.MainArea_photoArea__1mL7k {
  max-width: 500px;
}
.MainArea_photoArea__1mL7k img {
  width: 100%;
}
.MainArea_btnArea__1M5YO {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.SecBlue_sec__1tug1 {
  background: linear-gradient(92.21deg, #005792 6.65%, #04118a 106.65%);
  padding: 100px;
}
.SecBlue_sec__1tug1 .SecBlue_h3__1PTIo {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 36px;
  color: #ffffff;
  margin-left: 3%;
  margin: 0 !important;
}
.SecBlue_sec__1tug1 .SecBlue_h2__2v4fa {
  font-family: "Poppins";
  font-weight: 800;
  font-size: 64px;
  color: #ffffff;
  margin-left: 3%;
  margin: 0 !important;
}
.SecBlue_sec__1tug1 .SecBlue_p__1VK0S {
  font-family: "Poppins";
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 100px;
}
.SecBlue_btnPart__1n-DE {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.MyFooter_topFoot__MDed- {
  position: relative;
  margin-top: 100px;
}
.MyFooter_timer__lz287 {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
}
.MyFooter_main__26gSA {
  background: #ffffff;
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
  border-radius: 26px;
  height: 270px;
  width: 1000px;
  margin-top: 33px;
}
.MyFooter_main__26gSA h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
  color: #005792;
  text-align: center;
}
.MyFooter_main__26gSA p {
  font-family: "Poppins";
  font-size: 16px;
  color: #005792;
  text-align: center;
}
.MyFooter_topMain__3Iy2O {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MyFooter_btnArea__2loC9 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
footer {
  background: #e4eefa;
  color: #005792;
  padding: 200px 100px 0 100px;
}
.MyFooter_menuArea__1us_b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.MyFooter_menuPart__2uStT {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul {
  list-style: none;
}
ul span {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}
li {
  line-height: 27px;
}
.MyFooter_first__3jkXH {
  margin-right: 200px;
}
.MyFooter_endFooter__l8T8B {
  text-align: center;
  margin-bottom: 20px;
}

.Middle_mid__zXWpT {
  background: #ffffff;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.3);
  border-radius: 26px;
  width: 350px;
  height: 220px;
  padding: 20px;
  margin-top: 20px;
}
.Middle_mid__zXWpT h2 {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 24px;
  color: #04118a;
}
.Middle_mid__zXWpT p {
  font-family: "Poppins";
  font-size: 16px;
  color: #475761;
}
.Middle_imageArea__3kdPU {
  width: 40px;
}
.Middle_imageArea__3kdPU img {
  width: 100%;
}
.Middle_mix__29vw_ {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
}
.Middle_mix__29vw_ span {
  font-size: 16px;
  color: #1daaff;
  margin-right: 10px;
}
.Middle_forMIx__17O-i {
  display: flex;
  justify-content: flex-end;
}

.BlueArea_blueArea__smWhW {
  background: linear-gradient(92.21deg, #005792 6.65%, #04118a 106.65%);
  padding: 50px;
}
.BlueArea_blueArea__smWhW .BlueArea_h3__1X-JT {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 46px;
  color: #ffffff;
  margin-left: 3%;
}
.BlueArea_blueArea__smWhW .BlueArea_h2__ScvlF {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 46px;
  color: #ffffff;
  margin-left: 3%;
}
.BlueArea_blueArea__smWhW .BlueArea_p__30PcC {
  font-family: "Poppins";
  font-size: 24px;
  color: #ffffff;
  margin-left: 3%;
}
.BlueArea_cardArea__1wgNR {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  justify-items: center;
}
.BlueArea_btnPart__a4kyi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.SmallCard_small__-Japn {
  width: 240px;
  height: 180px;
  background: #fff;
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
  border-radius: 26px;
  padding: 10px 20px;
  margin-right: 20px;
}

.Tall_tall__weD0v {
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
  border-radius: 26px;
  width: 320px;
  padding: 10px 20px;
}
.Tall_tall__weD0v h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
}
.Tall_tall__weD0v h4 {
  font-family: "Poppins";
  font-size: 18px;
}
.Tall_tallChild__jdPUQ p {
  font-family: "Poppins";
  font-size: 16px;
}
.Tall_tall__weD0v strong {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
}
.Tall_btnPart__3y38z{
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 20px; 
}
.PaymantPart_paymant__1CS7_ {
  margin-top: 100px;
  padding: 50px 100px;
}
.PaymantPart_paymant__1CS7_ h3 {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 40px;
  text-align: center;
  color: #04118a;
}
.PaymantPart_paymant__1CS7_ h2 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  color: #04118a;
}
.PaymantPart_paymant__1CS7_ .PaymantPart_p__IUHHy {
  font-family: "Poppins";
  font-size: 24px;
  text-align: center;
  color: #1daaff;
}
.PaymantPart_cardsArea__21iIB {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PaymantPart_cardsArea__21iIB > * {
  margin: 0 20px;
}
.PaymantPart_footBar__6ddLd {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #04118a;
}

.SocialPart_soc__hQGJH {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 5%;
}
.SocialPart_subTitle__2TXl1 {
  font-family: "Poppins";
  font-size: 42px;
  color: #005792;
}
.SocialPart_title__kOgWl {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 64px;
  color: #005792;
}
.SocialPart_describtion__3KJtf {
  font-family: "Poppins";
  font-size: 18px;
  color: #005792;
}
.SocialPart_platforms__3YoYZ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5%;
}
.SocialPart_platforms__3YoYZ > * {
  margin-right: 20px;
}
.SocialPart_bottomPart__1HbQC {
  margin-top: 100px;
  padding-left: 25%;
}
.SocialPart_bottomPart__1HbQC p {
  font-size: 32px;
  color: #005792;
}
.SocialPart_bottomPart__1HbQC h2 {
  font-weight: 600;
  font-size: 46px;
  color: #005792;
}

.MediaSoc_media__3UuYD {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MediaSoc_media__3UuYD p {
  font-family: "Poppins";
  font-size: 18px;
  color: #005792;
  margin-left: 10px;
}

.Staps_steps__2c_9i {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4eefa;
  height: 400px;
}
.Staps_imageArea__MQAoU {
  width: 60em;
}
.Staps_imageArea__MQAoU img {
  width: 100%;
}

.WorkersArea_workers__1wKyU {
  margin-top: 100px;
  padding: 50px 100px;
}
.WorkersArea_workers__1wKyU h2 {
  font-family: "Poppins";
  font-size: 28px;
  line-height: 54px;
  color: #1daaff;
}
.WorkersArea_workers__1wKyU b {
  font-family: "Poppins";
  font-weight: 800;
  font-size: 48px;
  text-transform: uppercase;
  color: #005792;
}
.WorkersArea_workPart__2JEa4 {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.WorkersArea_workPart__2JEa4 > * {
  margin-right: 100px;
}
.WorkersArea_sms__3eJTn {
  height: 150px;
  background: #fff;
  border-radius: 26px;
  width: 300px;
  padding: 10px;
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
}
.WorkersArea_sms__3eJTn span {
  margin-left: 20px;
}

.Home_midArea__2ZRSl {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}
.Home_midArea__2ZRSl > * {
  margin-right: 50px;
}
.Home_roundedAreas__JN9U8 {
  width: 400px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
  border-radius: 26px;
  color: #005792;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Home_roundedAreas__JN9U8 b {
  font-weight: 600;
  font-size: 28px;
}
.Home_roundedAreas__JN9U8 p {
  font-size: 16px;
}
.Home_rounds__1MNfr {
  width: 160px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(134, 134, 134, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Home_rounds__1MNfr span {
  font-weight: 600;
  font-size: 16px;
  color: #005792;
}
.Home_secMidArea__CqHJR {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.MyLabel_dv__1GgQ_ label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.MyLabel_dv__1GgQ_ label p {
  font-family: "Mulish";
  font-weight: bold;
  font-size: 14px;
  color: #303030;
  margin-left: 15px;
  display: flex;
  justify-content: flex-start;
}
.MyLabel_dv__1GgQ_ label span {
  color: red;
}
.MyLabel_dv__1GgQ_ label input {
  background: #ffffff;
  border: 1px solid #99c8ff;
  box-sizing: border-box;
  border-radius: 8px;
  width: 40rem;
  height: 75px;
  font-size: 16px;
  color: #acacac;
  padding: 10px;
}
.MyLabel_dv__1GgQ_ label input:-ms-input-placeholder {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 16px;
  color: #acacac;
}
.MyLabel_dv__1GgQ_ label input::placeholder {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 16px;
  color: #acacac;
}

.Login_log__382gI {
  background: linear-gradient(
    107.49deg,
    rgba(77, 124, 193, 0.23) 0%,
    rgba(165, 216, 255, 0.06) 100%
  );
  height: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login_all__68rrs {
  position: relative;
}
.Login_imgArea__2FtZv {
  position: absolute;
  left: 37%;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(200, 200, 200, 0.22);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.Login_login__2NNKS {
  background: #ffffff;
  box-shadow: 0px 4px 47px rgba(218, 218, 218, 0.18);
  border-radius: 16px;
  width: 50em;
  height: 60%;
  padding: 20px 40px;
  margin-top: 50px;
  padding-top: 50px;
}

.Login_login__2NNKS h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #04118a;
  margin: 0 !important;
}
.Login_login__2NNKS p {
  font-family: "Poppins";
  font-size: 18px;
  text-align: center;
  color: #04118a;
  margin: 10px !important;
  font-weight: normal;
  font-weight: 200;
}
.Login_btn__2bK7z {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.Login_to__130IE {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #04118a;
}
.Login_to__130IE a {
  font-weight: 600 !important;
  font-size: 18px;
  color: #04118a;
}

.Narrow_narrow__15ZTC {
  background: #ffffff;
  box-shadow: 0px 6px 25px rgba(101, 101, 101, 0.1);
  border-radius: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 20px 0;
}
.Narrow_text__19uvL {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  margin-right: 40rem;
  flex-wrap: nowrap;
  color: #04118a;
}

.Pricing_mainText__18N6_ {
  padding: 100px;
  /* margin-bottom: 100px; */
}
.Pricing_mainText__18N6_ h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 72px;
  text-align: center;
  color: #005792;
}
.Pricing_mainText__18N6_ p {
  font-family: "Poppins";
  line-height: 27px;
  font-size: 18px;
  text-align: center;
  color: #2fabff;
}
.Pricing_cardsArea__Wmg7M {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Pricing_cardsArea__Wmg7M > * {
  margin: 0 20px;
}
.Pricing_footBar__1fW_P {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #04118a;
}
.Pricing_subTit__1tZES {
  margin-top: 100px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 46px;
  color: #04118a;
  text-align: center;
}
.Pricing_subInfo__LvjPh {
  font-family: "Poppins";
  font-size: 18px;
  text-align: center;
  color: #2fabff;
  margin-bottom: 100px;
}
.Pricing_sect__2y6tx {
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  margin-bottom: 100px;
}

.Register_log__3YlJt {
  background: linear-gradient(
    107.49deg,
    rgba(77, 124, 193, 0.23) 0%,
    rgba(165, 216, 255, 0.06) 100%
  );
  height: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Register_all__1rWuF {
  position: relative;
}
.Register_imgArea__3q47H {
  position: absolute;
  left: 37%;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(200, 200, 200, 0.22);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.Register_login__1uaHd {
  background: #ffffff;
  box-shadow: 0px 4px 47px rgba(218, 218, 218, 0.18);
  border-radius: 16px;
  width: 50em;
  padding: 20px 40px;
  margin-top: 50px;
  padding-top: 50px;
}

.Register_login__1uaHd h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #04118a;
  margin: 0 !important;
}
.Register_login__1uaHd p {
  font-family: "Poppins";
  font-size: 18px;
  text-align: center;
  color: #04118a;
  margin: 10px !important;
  font-weight: normal;
  font-weight: 200;
}
.Register_login__1uaHd .Register_p__2kNRH {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4d7cc1;
  margin-top: 20px !important;
}
.Register_btn__1kjnI {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.Register_to__15vcg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #04118a;
}
.Register_to__15vcg a {
  font-weight: 600 !important;
  font-size: 18px;
  color: #04118a;
}

.Dashboard_blueTitles__1noIc {
  color: #1daaff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Dashboard_blueTitles__1noIc > * {
  margin-right: 10px;
}
.Dashboard_subInfo__MOerN {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Dashboard_subInfo__MOerN > * {
  margin-right: 10px;
}
.Dashboard_subInfo__MOerN h5 {
  font-weight: 500;
  font-size: 18px;
  color: #a09da1;
}
.Dashboard_subInfo__MOerN span {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
}
.Dashboard_choise__36CoH {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px 20px 10px 20px;
}
.Dashboard_choise__36CoH > * {
  margin-left: 10px;
}
.Dashboard_age__2U1xv {
  width: 250px;
  height: 110px;
  background: linear-gradient(
    88.56deg,
    rgba(0, 87, 146, 0.35) -23.12%,
    rgba(0, 87, 146, 0.4) -23.12%,
    #2fabff -23.11%,
    #04118a 104.28%
  );
  border-radius: 6px;
  padding: 10px;
}
.Dashboard_age__2U1xv h3 {
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin: 5px;
}
.Dashboard_topPart__c9Rkw {
  background: #e3e3e3;
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 650px;
  padding: 5px 0;
  margin-bottom: 10px;
}
.Dashboard_topPart__c9Rkw span {
  color: #475761;
}
.Dashboard_bottomLeft__D0Kgn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UniqueTable_top__2txDZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.UniqueTable_topBtn__3k00t {
  display: flex;
  align-items: center;
}
.UniqueTable_topBtn__3k00t > * {
  margin-right: 10px;
}
.UniqueTable_searchPart__MpGmX {
  position: relative;
  border-radius: 6px;
}
.UniqueTable_searchPart__MpGmX > input {
  border: none;
  border-radius: 6px;
  background-color: #f4f4f4;
  width: 350px;
  height: 45px;
  padding-left: 15px;
}
.UniqueTable_table__33A4_ {
  margin-top: 50px;
}
.UniqueTable_headTable__3ZVJq {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1400px;
  margin-bottom: 5px;
}
.UniqueTable_myTable__75Ppw > * {
  display: flex;
  justify-content: center;
  align-items: center;
}
.UniqueTable_imgBox__Pjzlf {
  display: flex;
  justify-content: center;
  align-items: center;
}
.UniqueTable_bodyTable__171wO {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  height: 60px;
  width: 1400px;
}
.UniqueTable_bodyTable__171wO > * {
  margin-right: 5px;
}
.UniqueTable_name__eThTm {
  font-weight: 500;
  font-size: 12px;
  color: #a09da1;
  text-align: center;
}
.UniqueTable_p__3XrzW {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a09da1;
}

.Businesses_head__3-xke {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Businesses_head__3-xke h1 {
  color: #005792;
  margin-left: 15px;
}
.Businesses_bus__x9-9S .Businesses_p__34DFq {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.Businesses_sub__1cRzA {
  margin-top: 30px;
  font-weight: 500;
  font-size: 22px;
  color: #a09da1;
}
.Businesses_tb__2CtOk {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 25px;
}
.Businesses_topTab__36c6y {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Businesses_btn__rn3O3 {
  background: linear-gradient(92.21deg, #005792 6.65%, #04118a 106.65%);
  box-shadow: 0px 6px 7px rgba(31, 31, 31, 0.15);
  border-radius: 6px;
  width: 280px;
  height: 45px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.Businesses_inps__3glx7 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Businesses_arrows__2hup1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.Businesses_arrows__2hup1 > * {
  margin-left: 10px;
}
.Businesses_table__145Su {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 3fr 2fr;
  margin-top: 20px;
}
table > * {
  justify-content: flex-start;
}
.Businesses_tit__2yKXO {
  margin: 20px;
  background: #f4f4f4;
  border-radius: 6px;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 10px;
}
.Businesses_borderDv__31VDA {
  border: 1px solid #dde1eb;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Accounts_head__3CGbn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Accounts_head__3CGbn h1 {
  color: #005792;
  margin-left: 15px;
}
.Accounts_acc__2ZSev .Accounts_p__3iC4d {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.Accounts_accMain__1hIZa {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  padding: 15px;
}
.Accounts_elem__26vhe {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 10px;
  width: 510px;
  margin-bottom: 20px;
}
.Accounts_topElem__3Cw9H {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Accounts_topElem__3Cw9H > * {
  margin-right: 25px;
}
.Accounts_one__pByLQ {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 10px;
}
.Accounts_one__pByLQ span {
  font-size: 18px;
  color: #1daaff;
  margin-left: 10px;
}
.Accounts_first__nXbnq {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  color: #42cc23;
  list-style: inside;
}
.Accounts_oneGoogle__GoPlo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 10px;
  width: 100%;
}
.Accounts_oneGoogle__GoPlo span {
  font-size: 18px;
  color: #1daaff;
  margin-left: 10px;
}
.Accounts_second__3J705 {
  font-weight: 500;
  font-size: 12px;
  color: #a09da1;
  list-style: inside;
}
.Accounts_selfElem__34_yj {
  background: #f4f4f4;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 510px;
  height: 105px;
}
.Accounts_selfElem__34_yj p {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  color: #6884a9;
}
.Accounts_pArea__2WZ_i {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #a09da1;
}
.Accounts_pArea__2WZ_i a {
  text-decoration: underline;
  color: #1daaff;
}

.Introduction_head__3vvtM {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Introduction_head__3vvtM h1 {
  color: #005792;
  margin-left: 15px;
}
.Introduction_int__24rPk .Introduction_p__3IPeC {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}

.Settings_head__3QE7S {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Settings_head__3QE7S h1 {
  color: #04118a;
  margin-left: 15px;
}
.Settings_bus__S2N2Q .Settings_p__14Y8X {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.Settings_topArea__2BnnE {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px;
}
.Settings_toggleTop__GidtP {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Settings_selectArea__jDhzo {
  display: flex;
  align-items: center;
}
.Settings_selectArea__jDhzo span {
  font-size: 18px;
  color: #1daaff;
  margin-left: 10px;
}
.Settings_pers__1rkDS {
  font-size: 18px;
  color: #a09da1;
}

.MyTable_my__2zCPy {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;
}
.MyTable_p__26lk8 {
  color: #a09da1;
  font-size: 14;
  margin-left: 20px;
  text-align: center;
}

.CreateAdd_head__2cm_Q {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CreateAdd_head__2cm_Q h1 {
  color: #005792;
  margin-left: 15px;
}
.CreateAdd_create__1ZPyr .CreateAdd_p__1aIGx {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}

.Platform_platform__254ua {
  padding: 0 5em;
}
.Platform_allPlatforms__cFwsW {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.Platform_plat__2Unxm {
  background: #f4f4f4;
  width: 300px;
  padding: 10px;
}
.Platform_fbInst__2qWxs {
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Platform_one__1zz7q {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 10px;
  background-color: #fff;
}
.Platform_one__1zz7q span {
  font-size: 18px;
  color: #1daaff;
  margin-left: 10px;
}
.Platform_selectArea__1OlD3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Platform_select__sl4nN {
  background: #1daaff;
  color: #fff;
  height: 40px;
  border: none;
  border-radius: 6px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
}
.Platform_option__2qemT {
  border-radius: 6px;
  border: none;
  background: #e7e7e7;
  height: 40px;
  outline: none;
  color: #a09da1;
  line-height: 40px;
  margin: 5px;
}
.Platform_oneGoogle__2Cb6l {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 10px;
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
}
.Platform_oneGoogle__2Cb6l span {
  font-size: 18px;
  color: #1daaff;
  margin-left: 10px;
}
.Platform_textArea__1nlB5 {
  background: #f4f4f4;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a09da1;
  margin: 20px 5px;
}

.MyBlocks_myblock__1L3y6 {
  padding: 50px 10px 20px 10px;
  margin: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.MyBlocks_myblock__1L3y6 h3 {
  font-size: 14px;
  text-align: center;
}
.MyBlocks_myblock__1L3y6 p {
  font-size: 12px;
  text-align: center;
}

.Objective_selectedArea__oJEhC {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Objective_comp__Uq8Bk {
  color: #a09da1;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}
.Objective_info__YMQes {
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 6px;
  width: 500px;
  padding: 10px;
}
.Objective_info__YMQes span {
  color: #a09da1;
}
.Objective_select__2S5jS {
  color: #c4c4ca;
  width: 100%;
  height: 40px;
}
.Objective_sel__2vZsc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.Adcloud_choice__nBsag {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Adcloud_mainParth__A5Z4O {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 5px;
  width: 800px;
}
.Adcloud_h__2KjWT {
  margin: 10px 0;
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.Adcloud_location__x0yoS {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
  margin: 0 10px;
}
.Adcloud_opt__ikki3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  margin: 10px 0;
}
.Adcloud_opt__ikki3 select {
  border: none;
  color: #a09da1;
}
.Adcloud_p__4Snsz {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.Adcloud_labs__3iuQS {
  color: #a09da1;
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
}
.Adcloud_labsArea__1VJGw {
  margin-bottom: 15px;
}
.Adcloud_labsArea__1VJGw > * {
  margin: 0 10px;
}
.Adcloud_beet__2JsIk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background: #f4f4f4;
  border-radius: 6px;
}
.Adcloud_ccc__38QGU {
  color: #c4c4c4;
  font-size: 16px;
}
.Adcloud_txt__17L2Z {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
}
.Adcloud_searchPart__27HGs {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  margin: 10px 0;
}
.Adcloud_searchCombine__3n84M {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 5px 5px 50px;
  margin: 0;
}
.Adcloud_underBTN__1MVRa {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AdType_choice__381Jm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AdType_mainParth__DFev6 {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 5px 50px 5px;
  width: 800px;
}
.AdType_h__1Wgds {
  font-weight: 500;
  font-size: 16px;
  color: #005792;
  text-align: center;
}
.AdType_elems__3SUPN {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px;
  margin: 15px 0;
}
.AdType_elements__2cPey {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px 5px;
  margin: 15px 0;
  display: grid;
  grid-template-columns: 6fr 6fr;
  justify-items: center;
}
.AdType_p__1vB5U {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.AdType_p__1vB5U > span {
  color: #1daaff;
}
.AdType_b__1VXId {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.AdType_txt__3Csaz {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AdType_elem1__394uw {
  padding: 5px;
  border-right: 1px solid #dde1eb;
}
.AdType_elem2__1Orpg {
  padding: 5px;
}
.AdType_elem2__1Orpg select {
  border: 1px solid rgb(244, 244, 244);
  box-sizing: border-box;
  border-radius: 6px;
  color: #a09da1;
  height: 40px;
  width: 350px;
  margin-top: 10px;
}
.AdType_elem__njWiG {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;
  width: 350px;
  height: 570px;
}
.AdType_oneSection__1KRnx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}
.AdType_forImgs__1G8ac {
  border-radius: 6px;
}
.AdType_greenArea__3AAsT {
  margin-top: 20px;
  border: 1px solid #42cc23;
  box-sizing: border-box;
  border-radius: 6px;
  color: #42cc23;
  padding: 10px;
}
.AdType_mailArea__1ylvg {
  height: 60px;
  background: #f4f4f4;
  margin: 20px 0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AdType_btnPart__3ZSNO {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.ReviewPublish_choice__1AKd9 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReviewPublish_mainParth__1-Iou {
  border: 1px solid #dde1eb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 5px;
  width: 800px;
  display: grid;
  grid-template-columns: 6fr 6fr;
  justify-items: center;
}
.ReviewPublish_right__yU-Ig {
  border-left: 1px solid #dde1eb;
  padding: 5px 10px;
}
.ReviewPublish_p__2xiE8 {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
  text-align: center;
}
.ReviewPublish_thisSpan__1r7J1 {
  font-weight: 500;
  font-size: 16px;
  color: #a09da1;
}
.ReviewPublish_lab__1LrL5 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dde1eb;
  padding: 3px;
  border-radius: 6px;
  color: #a09da1;
}
.ReviewPublish_lab__1LrL5 input {
  outline: none;
  border: none;
  height: 40px;
}
.ReviewPublish_btnArea__23Gx8 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.ReviewPublish_btnArea__23Gx8 > * {
  margin: 0 25px 0 50px;
}

