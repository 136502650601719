.soc {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 5%;
}
.subTitle {
  font-family: "Poppins";
  font-size: 42px;
  color: #005792;
}
.title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 64px;
  color: #005792;
}
.describtion {
  font-family: "Poppins";
  font-size: 18px;
  color: #005792;
}
.platforms {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5%;
}
.platforms > * {
  margin-right: 20px;
}
.bottomPart {
  margin-top: 100px;
  padding-left: 25%;
}
.bottomPart p {
  font-size: 32px;
  color: #005792;
}
.bottomPart h2 {
  font-weight: 600;
  font-size: 46px;
  color: #005792;
}
